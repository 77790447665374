<!--文章列表-->
<template>
  <!--路径s-->
  <div class="path">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" >
        <span><router-link to="/"> 首页</router-link></span> &nbsp; &gt; &nbsp; {{pathName}} <span v-html="pathKey"></span>
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--路径s--> 
  <div class="box-p">
    <el-row :gutter="0">
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :xs="0" :sm="1" :md="1" :lg="1" ></el-col>
      <el-col :xs="24" :sm="22" :md="20" :lg="18">
        <div class="newslist">
            <ul>
                <li v-for="(item,index) in listData" :key="index">
                  <!-- <span>· <router-link :to="{ path: '/news', query: { id: item.id }}" target="_blank">{{item.contentTitle.substring(0,50)}}</router-link></span> -->
                  <span>· <a :href="`news?id=${item.id}`" target="_blank">{{item.contentTitle.substring(0,50)}}</a></span>
                  <time>{{formatDate(item.contentDatetime)}}</time>
                </li>         
            </ul>
            <el-empty v-if="listData.length === 0" :image-size="250" description="暂无信息" />
        </div>
        <div class="pagination">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[10, 15, 20, 30, 40]"
              :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total='tableTotal'>
            </el-pagination>
        </div>        
      </el-col>
      <el-col :xs="0" :sm="1" :md="1" :lg="1"></el-col>
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
    </el-row>
  </div>  
</template>

<script>
import {formatDate} from '@/utils/datetime_utils.js'
export default {
  name: '/newslist',  
  data () {
    return {
      pathKey: '',
      newsKey: '',
      sid: '',
      currentPage: 1,
      pagesize: 20,
      tableTotal: 0,
      listData: [],
      pathName: ''
    }
  },
  mounted () {
    if (this.$route.query.key === undefined || this.$route.query.key === '') {
      this.newsKey = '' 
      this.pathKey = ''   
    } else {
      this.newsKey = decodeURIComponent(this.$route.query.key)
      this.pathKey = '&nbsp; &gt; &nbsp;搜索关键字 ' + this.newsKey
    }

    if (this.$route.query.id === undefined || this.$route.query.key === '') {
      this.sid = ''
    } else {
      this.sid = this.$route.query.id
    }    
    this.getListData()
  },
  methods: {
    // pageSize 新每页条数改变时触发(每页下拉显示数据)
    handleSizeChange (size) {
      this.pagesize = size
      this.getListData()
    },
    // 新当前页改变时触发(点击第几页)
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.getListData()
    },
    async getListData () {
      const params = '?categoryId=' + this.sid + '&size='+ this.pagesize + '&current=' + this.currentPage + '&contentTitle=' + this.newsKey
      console.log('params=', params)
      const { data: ret } = await this.$http.get1(this.$api.newslist + params)      
      console.log('ret=', ret)
      if (ret.code === 1) {
        this.listData = ret.data.records
        this.tableTotal = ret.data.total
        // console.log('rows=', this.messageData)
        if (this.listData.length > 0) {
          this.pathName = this.listData[0].categoryId_name
        }        
      } else {
        this.$message.error(ret.msg)        
      }     
    },
    // 显示时间格式'yyyy-MM-dd hh:mm:ss'
    formatDate(time){
      return formatDate(time,'yyyy-MM-dd hh:mm')
    }    
  },
  watch: {
    // 监听路由query中key的变化
    $route (to, from) {
      console.log('from=', from)
      console.log('to=', to)
      console.log('from.query.key=', from.query.key)
      // query中key不同时重新查询资源
      if (to.query.key !== from.query.key) {
        this.newsKey= decodeURIComponent(to.query.key)
        this.getListData()
        if (this.newsKey === '') {
          this.pathKey = ''
        } else {
          this.pathKey = '&nbsp; &gt; &nbsp;搜索关键字 ' + this.newsKey
        }        
      }      
    }
  }
}
</script>

<style scoped>
</style>